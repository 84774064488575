<template>
    <div class="container gradient_box">
        <div class="header">
            <img src="../../assets/images/moli/logo.png" alt="Molly Tea Logo" class="logo">
            <h1 class="title">Win a Jasmine Tea Latte</h1>
            <p class="subtitle">Select premium ingredients to craft your perfect Jasmine Tea Latte!</p>
        </div>
        <div class="middle received">
            <div class="cup"></div>
            <div class="main">
                <div class="reward-card">
                    <h2 class="congrats-text">Sorry, the bubble tea making event is over!</h2>
                    <!-- <button class="login-button" @click="doClose">Close</button> -->
                </div>
                <div class="minipass">Powered by minipass</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'MoliGameOver',
        data() {
            return {
            };
        },
        beforeUpdate() {},
        updated() {},
        beforeCreate() {},
        created() {
        },
        watch: {
        },
        computed: {
            ...mapGetters({
                msg: 'app/get_app_msg'
            }),
            ...mapState({
            }),
        },
        methods: {
            ...mapActions({
                initData: 'app/initData',
            }),
            ...mapMutations({
            }),
            doClose() {
                window.close();
            }
        }
    };
</script>

<style scoped>
    @import '../../assets/style/moli/base.css';
    @import '../../assets/style/moli/index.css';

    .received .cup {
        background-image: url(../../assets/images/moli/over.png);
        background-size: auto 100%;
        background-position: center right;
    }
    .congrats-text {
        color: #999;
    }
</style>